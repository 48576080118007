import { ContentLayout, AppLayout, SpaceBetween } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Breadcrumbs, LoadingPage, Navigation } from "../../components";
import { FRONTEND_SELECTORS } from "../../common/constants";
import { DetailsTable } from "../../order/components/OrderDetailsCard";
import { OrderItemDetails } from "../../order/components/OrderItemDetailsCard";
import { FulfillmentDetails } from "../../fulfillment/components/FulfillmentDetailsCard";
import { useOrdersDetails } from "../../order/components/useOrderDetailsCard";
import { Header } from "@amzn/awsui-components-react";
import { BadRequestContent } from "../error/BadRequest";
import { BillingDetails } from "../../order/components/OrderBillingDetailsCard";
import { clientIntegrationIsEnabled } from "../../common/featureFlags";

function OrderDetailsContent(): JSX.Element {
  const intl = useIntl();
  const { orderDetailsData, orderDetailsError, orderDetailsLoading, pollingError } = useOrdersDetails();
  return pollingError ||
    (orderDetailsError && orderDetailsError.message !== "undefined") ||
    (orderDetailsData &&
      !orderDetailsLoading &&
      orderDetailsData.orderById.fulfillment.fulfillmentItems.length === 0) ? (
    <BadRequestContent />
  ) : orderDetailsLoading ? (
    <LoadingPage text={intl.formatMessage({ id: "loadingPage.loadingText.loadingOrderDetails" })} />
  ) : (
    <ContentLayout
      header={
        <div data-testid="appHeader-heading">
          <Header variant="awsui-h1-sticky">{intl.formatMessage({ id: "orderDetailsPage.header" })}</Header>
        </div>
      }
    >
      {!orderDetailsLoading &&
      orderDetailsData &&
      orderDetailsData.orderById &&
      orderDetailsData.orderById.fulfillment &&
      orderDetailsData.orderById.items &&
      orderDetailsData.orderById.items.length > 0 ? (
        <SpaceBetween direction="vertical" size="l">
          <DetailsTable order={orderDetailsData.orderById} />
          <OrderItemDetails order={orderDetailsData.orderById} />
          {clientIntegrationIsEnabled() ? <BillingDetails orderItem={orderDetailsData.orderById.items} /> : <div></div>}
          <FulfillmentDetails fulfillment={orderDetailsData.orderById.fulfillment} />
        </SpaceBetween>
      ) : (
        <div></div>
      )}
    </ContentLayout>
  );
}

export function OrderDetails(): JSX.Element {
  const intl = useIntl();
  return (
    <AppLayout
      content={<OrderDetailsContent />}
      breadcrumbs={<Breadcrumbs />}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      navigation={<Navigation />}
      toolsHide
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
    />
  );
}
