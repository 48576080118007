import { Header, Container, ColumnLayout, SpaceBetween, Box, Link } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Fulfillment } from "../types";
import { OrderItem } from "../../order/types";
import { ProductModality } from "../../onboarding/types";

const ValueWithLabel = (props: { label: any; children: any }) => (
  <div style={{ marginTop: "10px" }}>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

export function FulfillmentDetails(props: { fulfillment: Fulfillment }): JSX.Element {
  const intl = useIntl();

  const parseDate = (rawDate: string) => {
    const months: string[] = [
      intl.formatMessage({ id: "dates.month.january" }),
      intl.formatMessage({ id: "dates.month.february" }),
      intl.formatMessage({ id: "dates.month.march" }),
      intl.formatMessage({ id: "dates.month.april" }),
      intl.formatMessage({ id: "dates.month.may" }),
      intl.formatMessage({ id: "dates.month.june" }),
      intl.formatMessage({ id: "dates.month.july" }),
      intl.formatMessage({ id: "dates.month.august" }),
      intl.formatMessage({ id: "dates.month.september" }),
      intl.formatMessage({ id: "dates.month.october" }),
      intl.formatMessage({ id: "dates.month.november" }),
      intl.formatMessage({ id: "dates.month.december" }),
    ];

    const date = new Date(rawDate);
    const isUTCDate = rawDate.endsWith("Z");
    return isUTCDate
      ? `${months[date.getUTCMonth()]} ${date.getUTCDate()}, ${date.getUTCFullYear()}`
      : `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const digitalFulfillmentItems = props.fulfillment.fulfillmentItems.filter((item) =>
    item.orderItems.some((orderItem: OrderItem) => orderItem.productModality === ProductModality.DIGITAL)
  );

  const fulfillmentData = digitalFulfillmentItems[0].digitalFulfillmentDetails;

  const resultData = digitalFulfillmentItems[0].resultData && digitalFulfillmentItems[0].resultData[0] ? true : false;

  const parsedResultData = resultData ? JSON.parse(digitalFulfillmentItems[0].resultData[0].value) : undefined;

  const supportTicketId =
    parsedResultData && parsedResultData.supportTicketId ? parsedResultData.supportTicketId : undefined;
  const onboardingTicketId =
    parsedResultData && parsedResultData.onboardingTicketId ? parsedResultData.onboardingTicketId : undefined;
  const skillBuilderBranchId =
    parsedResultData && parsedResultData.skillBuilderBranchId ? parsedResultData.skillBuilderBranchId : undefined;
  const isManuallyFulfilled =
    parsedResultData && parsedResultData.fulfillManually ? parsedResultData.fulfillManually : false;

  function convertStatus(status: string) {
    let convertedStatus;
    switch (status) {
      case "PENDING":
        return (convertedStatus = "Pending");
      case "IN_PROGRESS":
        return (convertedStatus = "In Progress");
      case "COMPLETED":
        return (convertedStatus = "Completed");
      case "FAILED":
        return (convertedStatus = "Failed");
    }
  }

  let classStart =
    parsedResultData && parsedResultData.classStartDate
      ? parsedResultData.classStartDate
      : digitalFulfillmentItems[0].orderItems[0].classStartDate;

  let classEnd =
    parsedResultData && parsedResultData.classEndDate
      ? parsedResultData.classEndDate
      : digitalFulfillmentItems[0].orderItems[0].classEndDate;

  let termLength = undefined;
  if (classStart && classEnd) {
    const startDate = new Date(classStart);
    const endDate = new Date(classEnd);
    endDate.setDate(endDate.getDate() + 1); // Include the end day in the term length.
    termLength = (endDate as any) - (startDate as any);
  }

  if (termLength) {
    termLength = Math.ceil(termLength / (1000 * 3600 * 24));
  }

  // extra step to parse and set to indefined just in case if classStartDate undefined in both cases
  classStart = classStart ? parseDate(classStart) : undefined;

  // extra step to parse and set to indefined just in case if classEndDate undefined in both cases
  classEnd = classEnd ? parseDate(classEnd) : undefined;

  return (
    <div>
      <Container
        header={
          <Header data-testid="fulfillment-items-table-header" variant="h2">
            {intl.formatMessage({ id: "orderDetailsPage.fulfillmentDetails" })}
          </Header>
        }
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.customerName" })}>
              <div>{fulfillmentData.organizationName}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.onboardingTicket" })}>
              <div data-testid="onboardingTicketData">
                {onboardingTicketId ? (
                  <Link href={`https://t.corp.amazon.com/${onboardingTicketId}`} target="_blank">
                    {onboardingTicketId}
                  </Link>
                ) : (
                  <div>Pending</div>
                )}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "reviewAndSubmit.label.sso" })}>
              <div data-testid="isSSOEnabledData">{fulfillmentData.ssoEnabled ? "Yes" : "No"}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.branchId" })}>
              {skillBuilderBranchId ? <div>{skillBuilderBranchId}</div> : <div>Pending</div>}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.supportTicket" })}>
              {supportTicketId ? (
                <Link href={`https://t.corp.amazon.com/${supportTicketId}`} target="_blank">
                  {supportTicketId}
                </Link>
              ) : (
                <div>Not applicable</div>
              )}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.identityProvider" })}>
              <div data-testid="identityProviderData">
                {fulfillmentData.identityProvider ? (
                  <div>{fulfillmentData.identityProvider}</div>
                ) : (
                  <div>Not applicable</div>
                )}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.classStart" })}>
              <div data-testid="classStartDate">{classStart ? <div>{classStart}</div> : <div>-</div>}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.learningAdministrators" })}
            >
              <div>
                {fulfillmentData.learningAdmins.map((e, index) => (
                  <div key={index}>
                    {e.firstName} {e.lastName}, {e.emailAddress}{" "}
                  </div>
                ))}{" "}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.SSOIntegrationContact" })}
            >
              <div data-testid="ssoContactData">
                {fulfillmentData.ssoEnabled ? (
                  <div>
                    <div>
                      {fulfillmentData.ssoContact?.firstName + " " + fulfillmentData.ssoContact?.lastName + " "}
                    </div>
                    <div>{fulfillmentData.ssoContact?.emailAddress}</div>
                  </div>
                ) : (
                  <div>Not applicable</div>
                )}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.classEndDate" })}>
              <div data-testid="classEndDate">{classEnd ? <div>{classEnd}</div> : <div>-</div>}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.fulfillmentStatus" })}>
              <div data-testid="statusData">{convertStatus(props.fulfillment.fulfillmentItems[0].status)}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.manuallyFulfilled" })}>
              <div data-testid="manuallyFulfilled">{isManuallyFulfilled ? "Yes" : "No"}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.termLength" })}>
              <div data-testid="termLengthInDays">{termLength ? <div>{termLength} days</div> : <div>-</div>}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.allowedEmailDomains" })}>
              <div data-testid="emailDomainsData">
                {fulfillmentData.emailDomains &&
                fulfillmentData.emailDomains.length > 0 &&
                fulfillmentData.emailDomains[0] !== ""
                  ? fulfillmentData.emailDomains.map((e) => <div key={e}>{e}</div>)
                  : "Not applicable"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label={intl.formatMessage({ id: "fulfillmentDetailsComponent.label.authenticationMethod" })}
            >
              <div data-testid="authenticationMethod">
                {digitalFulfillmentItems[0].orderItems[0].productOnboardingDetails?.authenticationMethod
                  ? digitalFulfillmentItems[0].orderItems[0].productOnboardingDetails.authenticationMethod
                  : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </div>
  );
}
