import { gql, useQuery } from "@apollo/client";
import { useAuth } from "../../hooks/useAuth";
import { RoleType } from "../../user/types";

export const GET_ORDERS = gql`
  query GetOrdersByLearningAccountId($id: ID!, $cursor: String) {
    learningAccountById(id: $id) {
      id
      orders(input: { search: "" }, pageSize: 20, cursor: $cursor) {
        nodes {
          auditMetadata {
            createdBy
          }
          id
          status
          opportunityId
          items {
            subTotal
            productModality
            status
          }
          fulfillment {
            status
          }
        }
        pageInfo {
          cursor
          size
        }
      }
    }
  }
`;

export const useGetOrdersByLearningAccountQuery = (filterText: string, cursor: string) => {
  const { user } = useAuth();

  return useQuery(GET_ORDERS, {
    variables: { id: filterText, cursor: cursor },
    skip: user.role === RoleType.POST_AUTH_PRE_VIAS,
  });
};
