import { Alert, Pagination } from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { DEBOUNCE_DELAY } from "../../common/constants";
import { getNodeEnvironment } from "../../common/nodeEnvironment";
import { CREATE_USER_ROUTE, INTERNAL_USER_ROUTE } from "../../common/routes";
import { AppHeader, TableNoMatchState } from "../../components";
import useDebounce from "../../hooks/useDebounce";
import { Role, Status } from "../constants";
import { useInternalUserTable } from "./useInternalUserTable";

const StyledAlert = styled(Alert)`
  margin-bottom: 5px;
`;

export function InternalUserTable(): JSX.Element {
  const intl = useIntl();
  const STAGE = getNodeEnvironment();
  const style = {
    maxWidth: "350px",
  };

  const {
    internalUsersLoading,
    internalUsersData,
    currentPageIndex,
    search,
    handleSearch,
    onFilterTextChange,
    onClearFilter,
    onPaginationChange,
    totalPages,
    pagination,
    totalCount,
  } = useInternalUserTable();

  type GandalfDetails = {
    audiencePath?: string;
  };

  type users = {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    userRole: string;
    status: string;
    gandalfDetails?: GandalfDetails;
  };

  useDebounce(
    () => {
      onFilterTextChange(search);
    },
    [search],
    DEBOUNCE_DELAY
  );

  return (
    <Table
      ariaLabels={{ tableLabel: "Users List" }}
      data-testid="table-id"
      loadingText={intl.formatMessage({ id: "internalUserTableComponent.loading" })}
      loading={internalUsersLoading}
      columnDefinitions={[
        {
          id: "Name",
          header: intl.formatMessage({ id: "internalUserTableComponent.header.name" }),
          cell: (item: users) => (
            <Link to={`${INTERNAL_USER_ROUTE}/${item.id}`}>{item.firstName + " " + item.lastName} </Link>
          ),
          minWidth: 200,
        },
        {
          id: "Email",
          header: intl.formatMessage({ id: "internalUserTableComponent.header.email" }),
          cell: (item: users) => <div>{item.emailAddress}</div>,
          minWidth: 200,
        },
        {
          id: "Role",
          header: intl.formatMessage({ id: "internalUserTableComponent.header.role" }),
          cell: (item: users) => <div>{Object.values(Role)[Object.keys(Role).indexOf(item.userRole)]}</div>,
          minWidth: 200,
        },
        {
          id: "Status",
          header: intl.formatMessage({ id: "internalUserTableComponent.header.status" }),
          cell: (item: users) => <div>{Object.values(Status)[Object.keys(Status).indexOf(item.status)]}</div>,
          minWidth: 200,
        },

        {
          id: "LoginMethod",
          header: intl.formatMessage({ id: "internalUserTableComponent.header.login.method" }),
          cell: (item: users) => <div>{item.gandalfDetails?.audiencePath ? item.gandalfDetails.audiencePath : ""}</div>,
          minWidth: 200,
        },
      ]}
      items={internalUsersData ? internalUsersData.internalUsers.nodes : []}
      empty={internalUsersData ? <TableNoMatchState onClearFilter={onClearFilter} /> : null}
      wrapLines={false}
      resizableColumns={true}
      variant="full-page"
      filter={
        <div style={style}>
          <TextFilter
            filteringText={search}
            onChange={(event: { detail: { filteringText: any } }) => handleSearch(event.detail.filteringText)}
            filteringPlaceholder={intl.formatMessage({ id: "internalUserTableComponent.searchbar" })}
            filteringClearAriaLabel={intl.formatMessage({
              id: "internalUserTableComponent.textFilter.clearButton.ariaLabel",
            })}
          />
        </div>
      }
      stickyHeader={true}
      header={
        <div>
          <AppHeader
            title={intl.formatMessage({ id: "userPage.header" })}
            counterValue={`${totalCount}`}
            buttonText="Add user"
            navigateTo={CREATE_USER_ROUTE}
          />
        </div>
      }
      pagination={
        <Pagination
          ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
          pagesCount={totalPages}
          currentPageIndex={currentPageIndex}
          disabled={false}
          onChange={(event: { detail: { currentPageIndex: any } }) => onPaginationChange(event.detail.currentPageIndex)}
          openEnd={pagination}
        />
      }
    />
  );
}
