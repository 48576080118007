import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import styled from "styled-components";
import { Header, Container, SpaceBetween, Box } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Order, OrderItem } from "../types";
import { ProductModality } from "../../onboarding/types";

const ItemsTable = styled(Table)`
  * > th:nth-child(1) {
    width: 8% !important;
  }
  * > th:nth-child(2) {
    width: 8% !important;
  }
  * > th:nth-child(3) {
    width: 8% !important;
  }
  * > th:nth-child(4) {
    width: 8% !important;
  }
`;

interface OrderProps {
  order: Order;
}

export function OrderItemDetails(props: OrderProps): JSX.Element {
  const intl = useIntl();
  const style = {
    maxWidth: "50%",
  };
  const filteredProps = props.order.items.filter((item) => item.productModality === ProductModality.DIGITAL);

  const renderItemsTable = (): JSX.Element => {
    return (
      <ItemsTable
        header={
          <Header data-testid="order-items-table-header" variant="h2">
            {intl.formatMessage({ id: "orderDetails.items" })}
          </Header>
        }
        variant="embedded"
        ariaLabels={{ tableLabel: intl.formatMessage({ id: "order.learningAccountPage.ordersTable.tableLabel" }) }}
        columnDefinitions={[
          {
            id: "Product",
            header: intl.formatMessage({ id: "orderItemsComponent.label.product" }),
            ariaLabel: (data: TableProps.LabelData) => intl.formatMessage({ id: "orderItemsComponent.label.product" }),
            cell: (item: OrderItem) => (item.productTitle ? item.productTitle : ""),
          },
          {
            id: "Quantity",
            header: intl.formatMessage({ id: "orderItemsComponent.label.quantity" }),
            ariaLabel: (data: TableProps.LabelData) => intl.formatMessage({ id: "orderItemsComponent.label.quantity" }),
            cell: (item: OrderItem) => (item.quantity === 1 ? `${item.quantity} seat` : `${item.quantity} seats`),
          },
          {
            id: "Item Price",
            header: intl.formatMessage({ id: "orderItemsComponent.label.itemPrice" }),
            ariaLabel: (data: TableProps.LabelData) =>
              intl.formatMessage({ id: "orderItemsComponent.label.itemPrice" }),
            cell: (item: OrderItem) => intl.formatNumber(item.unitPrice ?? 0, { style: "currency", currency: "USD" }),
          },
          {
            id: "Total",
            header: intl.formatMessage({ id: "orderItemsComponent.label.total" }),
            ariaLabel: (data: TableProps.LabelData) => intl.formatMessage({ id: "orderItemsComponent.label.total" }),
            cell: (item: OrderItem) => intl.formatNumber(item.subTotal ?? 0, { style: "currency", currency: "USD" }),
          },
        ]}
        footer={<Box>{/*Cheeky way to get line under last table element*/}</Box>}
        items={filteredProps.length ? filteredProps : []}
      />
    );
  };
  return (
    <Container>
      <SpaceBetween direction="vertical" size="xxl">
        {renderItemsTable()}
      </SpaceBetween>
    </Container>
  );
}
