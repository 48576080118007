import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import fetch from "node-fetch";
import { getClientEndpoint } from "./constants";

const httpLink = createHttpLink({
  uri: getClientEndpoint(),
  credentials: "include",
  preserveHeaderCase: true,
  fetch: fetch as any,
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          my: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});
