import { AppLayout, Button, ContentLayout, Flashbar, FlashbarProps, Header } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FRONTEND_SELECTORS, RENDER_USER_INFO_FIELDS_FOR_EXTERNAL_USERS } from "../../common/constants";
import { LEARNING_ACCOUNTS_ROUTE } from "../../common/routes";
import { showError } from "../../common/utilityFunctions";
import { Breadcrumbs, LoadingPage, Navigation } from "../../components";
import { useAuth } from "../../hooks/useAuth";
import { BadRequest } from "../../pages";
import { useRemoveUserFromOrganizationMutation } from "../api/useRemoveUserFromOrganizationMutation";
import { useLAUserInfoCard } from "../components/useLAUserInfoCard";
import { UserInfoCard } from "../components/UserInfoCard";
import { RemoveUserFromOrganizationInput } from "../types";
import { isAuthorizedForUserActions } from "../utils";

const StyledFlashbar = styled(Flashbar)`
  padding-top: 8px;
`;

type LAUserDetailContentProps = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
};

function LAUserDetailContent(props: LAUserDetailContentProps): JSX.Element {
  const intl = useIntl();
  const [flashbarItems, setFlashbarItems] = useState([{}]);
  const navigate = useNavigate();
  const { user } = useAuth();

  const currentLearningAccountId = useLocation().pathname.split("/")[2];
  const userId = useLocation().pathname.split("/")[4];

  const removeUserFromOrganizationInput = (): RemoveUserFromOrganizationInput => {
    return {
      organizationId: currentLearningAccountId,
      userId: userId,
    };
  };

  const [removeUserFromOrganization, { data: removeUserFromOrgResponseData, error: removeUserFromOrgError }] =
    useRemoveUserFromOrganizationMutation(removeUserFromOrganizationInput());

  const setErrorFlashbar = async () => {
    setFlashbarItems([
      {
        type: "error",
        content: intl.formatMessage({ id: "userComponent.flashbar.errorMessage.remove" }),
        dismissLabel: "Dismiss message",
        dismissible: true,
        onDismiss: () => {
          setFlashbarItems([]);
        },
        statusIconAriaLabel: "error",
      },
    ]);
  };

  useEffect(() => {
    if (removeUserFromOrgError) {
      setErrorFlashbar();
    }
  }, [removeUserFromOrgError]);

  useEffect(() => {
    if (removeUserFromOrgResponseData && removeUserFromOrgResponseData.removeUserFromOrganization) {
      navigate(`${LEARNING_ACCOUNTS_ROUTE}/${currentLearningAccountId}/users`, {
        state: { successMessage: intl.formatMessage({ id: "userComponent.flashbar.successMessage.remove" }) },
      });
    }
  }, [removeUserFromOrgResponseData]);

  return (
    <ContentLayout
      header={
        <Header
          variant="awsui-h1-sticky"
          actions={
            isAuthorizedForUserActions(user) && user.email !== props.emailAddress ? (
              <Button
                variant="primary"
                data-testid="appHeader-button-remove"
                onClick={() => removeUserFromOrganization({ variables: { ...removeUserFromOrganizationInput() } })}
              >
                {intl.formatMessage({ id: "learningAccountsUserDetailPage.buttons.remove" })}
              </Button>
            ) : undefined
          }
        >
          {props.firstName ? `${props.firstName} ${props.lastName}` : ""}
        </Header>
      }
    >
      <UserInfoCard userType={RENDER_USER_INFO_FIELDS_FOR_EXTERNAL_USERS} />
      {removeUserFromOrgError ? (
        <Flashbar aria-live="polite" data-testid="error-flashbar" items={flashbarItems} />
      ) : null}
    </ContentLayout>
  );
}

export function LAUserDetail(): JSX.Element {
  const intl = useIntl();
  const { userDetailLoading, userDetailError, firstName, lastName, emailAddress } = useLAUserInfoCard();
  const { state } = useLocation();
  const [items, setItems] = useState(
    state && state.action
      ? ([
          {
            type: "success",
            content: intl.formatMessage({ id: `userComponent.flashbar.successMessage.${state.action.toLowerCase()}` }),
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setItems([]),
            statusIconAriaLabel: "success",
          },
        ] as FlashbarProps.MessageDefinition[])
      : []
  );

  return showError(userDetailError) ? (
    <BadRequest />
  ) : (
    <AppLayout
      content={
        userDetailLoading ? (
          <LoadingPage text={intl.formatMessage({ id: "loadingPage.loadingText.loadingDetails" })} />
        ) : (
          <LAUserDetailContent firstName={firstName} lastName={lastName} emailAddress={emailAddress} />
        )
      }
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      notifications={state && <StyledFlashbar items={items} />}
      breadcrumbs={<Breadcrumbs />}
      navigation={<Navigation />}
      ariaLabels={{
        navigation: intl.formatMessage({ id: "appLayout.ariaLabels.navigation" }),
        navigationClose: intl.formatMessage({ id: "appLayout.ariaLabels.navigationClose" }),
        navigationToggle: intl.formatMessage({ id: "appLayout.ariaLabels.navigationToggle" }),
      }}
      toolsHide
    />
  );
}
